/* @font-face {
  font-family: 'System';
  font-style: normal;
  font-weight: normal;
  src: local('IBM VGA 8x16'), url('./assets/font/WebPlus_IBM_VGA_8x16.woff') format('woff');
} */

@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap');

.App {
  text-align: center;
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.12em;
  color: white;
  background-color: transparent;
}

a {
  color:"#FAFEFA" !important;
  background-color: '#671122';
  text-decoration: 'none';
  text-decoration-color: '#767000';
}

* {
  box-sizing: border-box;
  color: #0e0e0e;
  font-family: 'Londrina Solid', sans-serif !important;
  letter-spacing: 0.12em;
  font-weight: 100;
}

html {
  scroll-behavior: smooth;
}

body {
  /* overflow: hidden; */
  background: rgb(250, 250, 250);
  color: #01ff01;
}

img {
  filter: drop-shadow(3px 3px 3px rgba(128, 126, 126, 0.7));
}

.descTitle {
  max-width: 900px;
  width: 85%;
  margin: 0px;
  margin-top: -35px;
  border-radius: 0px;
}

.descImage {
  max-width: 560px;
  width: 60%;
  margin: 0px;
  border-radius: 0px;
}


.aboveAll {
  position: absolute;
  top:300px;left:50%;
  transform: translateX(-50%);
  justify-content:"center";
  align-items:"center";
  height: auto;
  background-color: #e6e6e6 !important;
  color: rgb(37, 37, 37);
  vertical-align: middle;
  margin: 20px;  
  padding: 20px;
  border-radius: 15px;
  z-index: 1000;
}

.container {
  position: relative;
  top: 0; left: 0;
}

.containerInside {
  position: relative;
  top: 0; left: 0;
}

.behind {
  position: absolute;
  top:50%;left:50%;
  transform: translate(-50%,-50%);
  z-index: -1;
}